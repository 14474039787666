import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Table, Link, ColumnsType, SecondaryText, Tooltip, Space, Button } from '~/core-components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { maskString } from '~/features/employee/util'
import { Permission, PermissionAction } from '~/constants'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { MutateEmFamilyDrawer } from './MutateEmFamilyDrawer'
import { EmFamilyState } from '../../../types'
import { fetchEmFamilies } from '../../../actions'
import { selectEmFamilies } from '../../../selectors'
import { useEmFamilyRequests, useSysOptions } from '../../../hooks'
import { FamilyRelation } from '~/constants'
import { EmFamilyRequestsDrawer } from './EmFamilyRequest/EmFamilyRequestsDrawer'

interface EmFamiliesProps {
  employeeId?: string
}

type EmFamilyTable = EmFamilyState

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmFamilyState
  readOnly?: boolean
}

interface RequestDrawerState {
  employeeId?: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_REQUEST_DRAWER_STATE: RequestDrawerState = { visible: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const labelRow1Style: CSSProperties = { lineHeight: 1.1 }
const labelRow2Style: CSSProperties = { fontSize: 12 }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmFamilies: FC<EmFamiliesProps> = ({ employeeId }: EmFamiliesProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const loading = useSelector((state: StoreState) => state.employee.emFamiliesLoading[employeeId || ''])
  const emFamilies = useSelector(selectEmFamilies)(employeeId)
  const nationalities = useSelector(selectKeyvaluesById)('nationality')
  const [identityTypes] = useSysOptions('identity_type')
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const [yesNo] = useSysOptions('yes_no')
  const canView = usePermissionGate(Permission.employeeFamily)
  const canModify = usePermissionGate(Permission.employeeFamily, PermissionAction.Modify)
  const readOnly = canView && !canModify
  const [emFamilyRequests, emFamilyRequestLoading] = useEmFamilyRequests(employeeId || '', inView)
  const [requestDrawerState, setRequestDrawerState] = useState<RequestDrawerState>(DEFAULT_REQUEST_DRAWER_STATE)

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('nationality', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView && employeeId) {
      dispatch(fetchEmFamilies(employeeId))
    }
  }, [inView, employeeId])

  const handleAddFamily = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditFamily = useCallback(
    (emFamily: EmFamilyState) => {
      setDrawerState({ visible: true, employeeId, data: emFamily, readOnly })
    },
    [employeeId, readOnly, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<EmFamilyTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>{value}</div>
          <SecondaryText style={labelRow2Style}>
            {record.relation && relationships[record.relation]?.value}{' '}
            {record.gender && `(${genders[record.gender]?.value})`}
          </SecondaryText>
        </>
      )
    },
    {
      title: 'Date of birth',
      key: 'birthDate',
      dataIndex: 'birthDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Nationality',
      key: 'nationalityId',
      dataIndex: 'nationalityId',
      render: (value: string) => value && nationalities[value]?.value
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Dependant',
      key: 'dependant',
      dataIndex: 'dependant',
      render: (value: boolean) => yesNo[value?.toString()]?.value
    },
    {
      title: 'Identification',
      key: 'identityNo',
      dataIndex: 'identityNo',
      render: (value: string, record) => (
        <>
          <div style={labelRow1Style}>
            <Tooltip title={value}>{maskString(value)}</Tooltip>
          </div>
          <SecondaryText style={labelRow2Style}>
            {record.identityType && identityTypes[record.identityType]?.value}
          </SecondaryText>
        </>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleEditFamily(record)}>
          {readOnly ? 'view' : 'edit'}
        </Link>
      )
    }
  ]

  const handleOpenRequestDrawer = useCallback(() => {
    setRequestDrawerState({ employeeId, visible: true })
  }, [employeeId])

  const handleCloseRequestDrawer = useCallback(() => {
    setRequestDrawerState(DEFAULT_REQUEST_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Family members"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          employeeId &&
          canModify && (
            <Space>
              {emFamilyRequests.length > 0 && (
                <Button
                  style={btnPendingStyle}
                  size="small"
                  onClick={handleOpenRequestDrawer}
                  loading={emFamilyRequestLoading}
                >
                  {emFamilyRequests.length > 1 ? `${emFamilyRequests.length} pending requests` : 'pending request'}
                </Button>
              )}
              <Link onClick={handleAddFamily}>add</Link>
            </Space>
          )
        }
      >
        <Table
          rowKey="id"
          dataSource={emFamilies}
          pagination={false}
          columns={columns}
          loading={loading}
          expandable={{
            expandedRowRender: (record: EmFamilyTable) => (
              <p style={{ margin: 0 }}>
                {`First child care leave : ${
                  record.relation === FamilyRelation.child && record.firstCclDate
                    ? moment(record.firstCclDate).format('DD MMM YYYY')
                    : '-'
                }`}
              </p>
            )
          }}
        />
        <MutateEmFamilyDrawer {...drawerState} onClose={handleCloseDrawer} />
        {canModify && emFamilyRequests.length > 0 && (
          <EmFamilyRequestsDrawer {...requestDrawerState} onClose={handleCloseRequestDrawer} />
        )}
      </Card>
    </div>
  )
}
