import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Spin } from '~/core-components'
import { Col, JsonDisplay, Row } from '~/components'
import { usePayGroupsDict } from '~/features/master'
import { useSysOptions } from '~/features/employee'
import { showSuccess } from '~/utils'
import { CpfPaymentMode, CpfSubmissionStatus } from '~/constants'
import { useCpfSubmission } from '../../hooks'
import { CpfSubmissionStatusTag } from './CpfSubmissionStatusTag'

interface CpfSubmissionDrawerProps {
  visible: boolean
  id?: string
  onClose: () => void
}

export const CpfSubmissionDrawer: FC<CpfSubmissionDrawerProps> = ({ visible, id, onClose }) => {
  const [data, loading] = useCpfSubmission(id)
  const [payGroupsDict] = usePayGroupsDict()
  const [paymentModeDict] = useSysOptions('cpf_payment_mode')
  const [qrCode, setQrCode] = useState<string>()
  const [isLogHidden, setIsLogHidden] = useState(true)

  useEffect(() => {
    try {
      setIsLogHidden(data.status !== CpfSubmissionStatus.failed)

      const responseJson = JSON.parse(data.responseContent)
      setQrCode(responseJson?.responseData?.paynowQrCode)
    } catch {
      console.error('Invalid JSON for parsing response content', data)
    }
  }, [data])

  const handleCopy = useCallback(() => {
    let jsonString = JSON.stringify(data)
    try {
      jsonString = JSON.stringify({
        ...data,
        requestContent: JSON.parse(data.requestContent),
        responseContent: JSON.parse(data.responseContent)
      })
    } catch {
      console.error('Invalid JSON for parse', data)
    }

    navigator.clipboard.writeText(jsonString).then(() => {
      showSuccess('Copied to clipboard')
    })
  }, [data])

  const handleShowLog = useCallback(() => {
    setIsLogHidden(false)
  }, [])

  return (
    <Drawer
      open={visible}
      title="CPF submission"
      onClose={onClose}
      width="800px"
      className="cpf-submission-drawer"
      footer={
        isLogHidden ? (
          <Button onClick={handleShowLog}>Show technical logs</Button>
        ) : (
          <Button onClick={handleCopy}>Copy to clipboard</Button>
        )
      }
    >
      <Form>
        {loading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            <Row>
              <Col>
                <Form.Item label="CPF submission no.">{data.cpfNo}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Payroll groups">
                  {data.payGroupIds?.map(id => payGroupsDict[id]?.name || '').join(', ')}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Payment month">{data.paymentMonth}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="File name">{data.fileName}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Advice code">{data.adviceCode}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Status">
                  <CpfSubmissionStatusTag status={data.status} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Payment mode">
                  {data.paymentMode === CpfPaymentMode.PayNow ? (
                    <>
                      <div>{paymentModeDict[data.paymentMode]?.value || ''}</div>
                      {qrCode && <img src={`data:image/png;base64,${qrCode}`} alt="PayNow QR Code" />}
                    </>
                  ) : (
                    paymentModeDict[data.paymentMode]?.value || ''
                  )}
                </Form.Item>
              </Col>
              <Col span={12} hidden={data.paymentMode !== CpfPaymentMode.DirectDebit}>
                <Form.Item label="Deduction date">
                  {data.deductionDate ? moment(data.deductionDate).format('dd MMM yyyy') : '-'}
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={isLogHidden}>
              <Col>
                <Form.Item label="Request">
                  <JsonDisplay jsonString={data.requestContent} />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={isLogHidden}>
              <Col>
                <Form.Item label="Response">
                  <JsonDisplay jsonString={data.responseContent} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Drawer>
  )
}
