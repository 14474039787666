import React, { FC, useCallback, useState, ChangeEvent, useEffect } from 'react'
import moment from 'moment-timezone'
import cronstrue from 'cronstrue'
import confirm from 'antd/lib/modal/confirm'
import { EditOutlined } from '@ant-design/icons'
import { Checkbox, CheckboxChangeEvent, Form, Input, SecondaryLink, Space } from '~/core-components'
import { Col, CronEditor, DrawerForm, JobKeyValues, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { addJob, deleteJob, updateJob } from '../../../actions'
import { useSysJob } from '../../../hooks'
import { IJob, JobRowState } from '../../../types'
import { mapJobStateToJob } from '../../../types/job.mapper'
import { refetchJobsView } from '~/features/job/reducers'
import { SysJobCode } from '~/constants'
import { ParamsPayProcess } from './ParamsPayProcess'
import { ParamsReminder } from './ParamsReminder'
import { ParamsReminderRepeat } from './ParamsReminderRepeat'
import { ParamsPayExportAllPayslips } from './ParamsPayExportAllPayslips'
import { ParamsReminderLeaveApproval } from './ParamsReminderLeaveApproval'
import { ParamsReminderClaimApproval } from './ParamsReminderClaimApproval'

export interface MutateJobDrawerProps {
  visible: boolean
  editing?: boolean
  onClose: () => void
  data?: JobRowState
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IJob = {
  id: '',
  sysJobCode: '',
  name: '',
  paramJsonb: '',
  cronExpr: '0 0 1/1 * *',
  inactiveDate: '',
  startDate: TODAY,
  endDate: '',
  notifyEmail: '',
  triggerJobId: ''
}

export const MutateJobDrawer: FC<MutateJobDrawerProps> = ({
  visible,
  editing,
  onClose,
  data
}: MutateJobDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState<IJob>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [sysJob] = useSysJob(formData.sysJobCode)
  const [isEditCron, setIsEditCron] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const cronTabs = sysJob?.interval?.split(',')
  const isNew = data ? false : true
  const isEditable = isNew || data?.isOwner

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
    setIsEditCron(isNew)
  }, [visible, setFocus, isNew])

  useEffect(() => {
    if (visible) {
      setIsEditing(!!editing)
    }
  }, [visible, editing])

  useEffect(() => {
    if (data) {
      const {
        id,
        sysJobCode,
        name,
        paramJsonb,
        cronExpr,
        inactiveDate,
        startDate,
        endDate,
        notifyEmail,
        triggerJobId
      } = data
      setFormData({
        id,
        sysJobCode,
        name,
        paramJsonb,
        cronExpr,
        inactiveDate,
        startDate,
        endDate,
        notifyEmail,
        triggerJobId
      })
      setIsInactive(!!data.inactiveDate)
    } else {
      setFormData(EMPTY_FORM_DATA)
      setIsInactive(false)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('sysJobCode' in updates) {
      setFormData({ ...EMPTY_FORM_DATA, ...updates })
    } else {
      setFormData(formData => ({ ...formData, ...updates }))
    }
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateJob(data.id, mapJobStateToJob(data), formData))
      } else {
        result = await dispatch(addJob(formData))
      }
      dispatch(refetchJobsView())
    } finally {
      setLoading(false)
    }
    if (result?.errors) {
      setErrors(result.errors)
    }
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, data, onClose])

  const handleDelete = useCallback(
    (job: IJob | undefined) => {
      if (job) {
        const { id, name } = job
        confirm({
          title: 'Delete job',
          content: `Do you want to delete "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteJob(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              dispatch(refetchJobsView())
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleEditCron = useCallback(() => {
    setIsEditCron(true)
  }, [])

  const getCronDisplay = useCallback(() => {
    if (formData.cronExpr) {
      const display = cronstrue.toString(formData.cronExpr, { throwExceptionOnParseError: false })
      if (display.search('undefined') === -1) {
        return display
      }
    }
    return '-'
  }, [formData.cronExpr])

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? (isEditing ? 'Edit job' : 'Job') : 'Add job'}
      okText={isEditable ? (!isEditing ? 'Edit' : 'Save') : 'Close'}
      onClose={onClose}
      confirmLoading={loading}
      width={520}
      showDelete={data && isEditing ? true : false}
      deleteDisabled={data?.lastRunDate ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-job"
    >
      <Form id="form-job" onFinish={isEditable ? (!isEditing ? handleToggleEdit : handleOk) : onClose}>
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item label="Type" validateStatus={errors?.sysJobCode ? 'error' : ''} help={errors?.sysJobCode}>
              {!isNew ? (
                <>{sysJob?.name || ''}</>
              ) : (
                <JobKeyValues
                  ref={focusRef}
                  id="sysJob"
                  value={formData.sysJobCode}
                  onChange={(value: string) => handleFormDataChange({ sysJobCode: value })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col flex="auto">
            <Form.Item
              label="Name the job (if different from above)"
              validateStatus={errors?.name ? 'error' : ''}
              help={errors?.name}
            >
              {isEditing ? (
                <Input
                  value={formData.name}
                  placeholder={sysJob?.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFormDataChange({ name: event.target.value })
                  }
                />
              ) : (
                <>{formData.name}</>
              )}
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              {isEditing ? (
                <Checkbox
                  checked={isInactive}
                  onChange={(event: CheckboxChangeEvent) => {
                    setIsInactive(event.target.checked)
                    handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                  }}
                />
              ) : (
                <>{isInactive ? 'Yes' : 'No'}</>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Start date" validateStatus={errors?.startDate ? 'error' : ''} help={errors?.startDate}>
              {isEditing ? (
                <Input.Date
                  value={formData.startDate ? moment(formData.startDate) : undefined}
                  onChange={(value: moment.Moment | null) =>
                    handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
                  }
                />
              ) : (
                <>{moment(formData.startDate).format('DD MMM YYYY')}</>
              )}
            </Form.Item>
          </Col>
        </Row>
        {!data?.isAdhoc && (
          <Row hidden={!formData.sysJobCode}>
            <Col span={24}>
              <Form.Item validateStatus={errors?.cronExpr ? 'error' : ''} help={errors?.cronExpr}>
                {!isEditCron ? (
                  <Space>
                    {getCronDisplay()}
                    {isEditing && (
                      <SecondaryLink onClick={handleEditCron}>
                        <EditOutlined />
                      </SecondaryLink>
                    )}
                  </Space>
                ) : (
                  <CronEditor
                    value={formData.cronExpr}
                    onChange={(value: string) => handleFormDataChange({ cronExpr: value })}
                    filterTabs={cronTabs}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {formData.sysJobCode === SysJobCode.PayProcess && (
          <Row>
            <Col span={24}>
              <ParamsPayProcess
                paramJsonb={formData.paramJsonb}
                isEditing={isEditing}
                onChange={(value: string) => handleFormDataChange({ paramJsonb: value })}
                errors={errors}
              />
            </Col>
          </Row>
        )}
        {formData.sysJobCode === SysJobCode.RemindLeaveApproval ? (
          <Row>
            <Col span={24}>
              <ParamsReminderLeaveApproval
                sysJobCode={formData.sysJobCode}
                paramJsonb={formData.paramJsonb}
                isEditing={isEditing}
                onChange={(value: string) => handleFormDataChange({ paramJsonb: value })}
                errors={errors}
              />
            </Col>
          </Row>
        ) : formData.sysJobCode === SysJobCode.RemindClaimApproval ? (
          <Row>
            <Col span={24}>
              <ParamsReminderClaimApproval
                sysJobCode={formData.sysJobCode}
                paramJsonb={formData.paramJsonb}
                isEditing={isEditing}
                onChange={(value: string) => handleFormDataChange({ paramJsonb: value })}
                errors={errors}
              />
            </Col>
          </Row>
        ) : formData.sysJobCode === SysJobCode.RemindIdExp ? (
          <Row>
            <Col span={24}>
              <ParamsReminderRepeat
                sysJobCode={formData.sysJobCode}
                paramJsonb={formData.paramJsonb}
                isEditing={isEditing}
                onChange={(value: string) => handleFormDataChange({ paramJsonb: value })}
                errors={errors}
              />
            </Col>
          </Row>
        ) : (
          formData.sysJobCode.startsWith('remind_') && (
            <Row>
              <Col span={24}>
                <ParamsReminder
                  sysJobCode={formData.sysJobCode}
                  paramJsonb={formData.paramJsonb}
                  isEditing={isEditing}
                  onChange={(value: string) => handleFormDataChange({ paramJsonb: value })}
                  errors={errors}
                />
              </Col>
            </Row>
          )
        )}
        {formData.sysJobCode === SysJobCode.PayExportAllPayslips && (
          <Row>
            <Col span={24}>
              <ParamsPayExportAllPayslips
                isAdhoc={data?.isAdhoc || false}
                paramJsonb={formData.paramJsonb}
                isEditing={isEditing}
                onChange={(value: string) => handleFormDataChange({ paramJsonb: value })}
                errors={errors}
              />
            </Col>
          </Row>
        )}
      </Form>
    </DrawerForm>
  )
}
